import React from "react"

const SpeedIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2963 7.03705L8.33331 22.5926H20L18.7037 32.963L31.6666 17.4074H20L21.2963 7.03705Z"
        fill="#475766"
        stroke="#475766"
        strokeWidth="2.58222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9.99998"
        cy="11.6667"
        r="7.49667"
        fill="#05AAFF"
        stroke="#FFF9E6"
        strokeWidth="1.66"
      />
      <circle
        cx="30"
        cy="28.3333"
        r="7.49667"
        fill="#05AAFF"
        stroke="#FFF9E6"
        strokeWidth="1.66"
      />
    </svg>
  )
}

export default SpeedIcon
