import Seo from "../../components/seo"
import React, { useEffect, useState, useRef, FunctionComponent } from "react"
import HeroScribble from "../../assets/heroScribble"
import FeatBanner1 from "../../images/S1-1.png"
import FeatBanner2 from "../../images/MT.png"
import FeatBanner3 from "../../images/KYT.png"
import CatchPhraseBlue from "../../images/cp-blue.png"
import CatchPhraseYellow from "../../images/cp-yellow.png"
import CatchPhraseRed from "../../images/cp-red.png"
import CatchPhrasePurple from "../../images/cp-purple.png"
import CatchPhraseOrange from "../../images/cp-orange.png"
import CatchPhraseGreen from "../../images/cp-green.png"
import HarshitMugshot from "../../images/harshit.png"
import NehalMugshot from "../../images/nehal.png"
import RuchaMugshot from "../../images/rucha.png"
import NishithMugshot from "../../images/nishith.png"
import RightArrow from "../../assets/rightArrow"
import SpeedoneononeVideo from "../../assets/SpeedoneononeVideo.mp4"
import KytVideo from "../../assets/KytVideo.mp4"
import MindfulTalksVideo from "../../assets/MindfulTalksVideo.mp4"

import { generateRandomNumber } from "../../utils/generateRandomNumber"
import Popover from "../../components/Popover"
import { MonitorIcon } from "../../assets/monitorIcon"
import { ArggIcon, CrossIcon, ShareIcon } from "../../assets/modalIcons"
import { initAmplitude, sendAmplitudeData } from "../../utils/amplitude"
import { AmplitudeEvents } from "../../types/amplitudeEvents"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../../utils/buttonClickScaleEffect"
import "./conversations.css"

import Footer from "../../components/Footer"
import LogoHeader from "../../components/LogoHeader"
import ColorSelector from "../../components/ColorSelector"
import { getPSTTime } from "../../utils/getPSTTime"
import { loadExternalScripts } from "../../utils/loadExternalScript"
import {
  ActivityDescription,
  ActivityEnum,
  CONVERSATIONS_DESCRIPTION_METADATA,
  CONVERSATIONS_TITLE_METADATA,
  DESCRIPTION_METADATA,
  mixersWithConversations,
  TITLE_METADATA,
} from "../../utils/constants"
import {
  TemplateIcon1,
  TemplateIcon2,
  TemplateIcon3,
  TemplateIcon4,
  TemplateIcon5,
} from "../../assets/templateIcons"
import TemplatesCoffeeIcon from "../../assets/templatesCoffeeIcon"
import GamePadIcon from "../../assets/gamePadIcon"
import WhatsInsideModal from "../../components/WhatsInsideModal"
import MessageIcon from "../../assets/messageIcon"
import FlagIcon from "../../assets/flagIcon"
import ThursdayLogo from "../../assets/thursdayLogo"
import FindNewThings from "../../assets/FindNewThings"
import PersonsSVG from "../../assets/personsSVG"
import { StarSVG } from "../../assets/starSVG"
import VideoSVG from "../../assets/videoSVG"
import FunPromptsScribble from "../../assets/FunPromptsScribble"
import SpeedIcon from "../../assets/SpeedIcon"
import MtIcon from "../../assets/MtIcon"
import KytIconColor from "../../assets/KytIconColor"
import SpeedIconWhite from "../../assets/SpeedIconWhite"
import KytIconWhite from "../../assets/KytIconWhite"
import MtIconWhite from "../../assets/MtIconWhite"

import RemoteTeams from "../../components/RemoteTeams"
import PaymentSection from "../../components/PaymentSection"
import TemplatesSection from "../../components/TemplatesSection"
import FloatingBannerContainer from "../../components/FloatingBannerContainer"
import MarketingFloatingBanner from "../../components/MarketingFloatingBanner"
import FreeMixerModal from "../../components/FreeMixerModal"
import Testimonials from "../../components/Testimonials"
import { startMixerHandler } from "../../utils/startMixerFunction"
import { utmCookieRequest } from "../../utils/handleUTFTracking"
import FaqSection from "../../components/FaqSection"

interface OpacityHandlerInterface {
  highestOpacityTxtIndex: number
  allElements: ChildNode[] | any
}
export const handleContactUs = () => {
  window.location.href = process.env.CONTACT_US_LINK
}

const ConversationPage: FunctionComponent = () => {
  const [catchPhraseIndex, setCatchPhraseIndex] = useState<number>(0)
  const [selectedVideoOptionIndex, setSelectedVideoOptionIndex] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showWI, setShowWI] = React.useState(false)
  const [templateNumber, setTemplateNumber] = React.useState(0)
  const [isJSLoaded, setIsJSLoaded] = useState(true)
  const vidSpeedRef = useRef()
  const vidKYTRef = useRef()
  const vidMTRef = useRef()
  const [showFreeMixerModal, setShowFreeMixerModal] = React.useState(false)

  const premiumPrice = "$5"

  const heroVideos = [
    { ref: vidSpeedRef, video: SpeedoneononeVideo },
    { ref: vidMTRef, video: MindfulTalksVideo },
    { ref: vidKYTRef, video: KytVideo },
  ]

  const videoOptions = [
    [<SpeedIconWhite />, "Speed 1-on-1s"],
    [<MtIconWhite />, "Mindful talks"],
    [<KytIconWhite />, "Know your team"],
  ]

  const handleCreateSocial = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL
    const joinThursdayLink = `${thursdayLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`

    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      // window?.open(thursdayLink, "_self")
      window.location.href = joinThursdayLink
    }
  }

  const freeSocialHandler = () => {
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      setIsModalVisible(true)
    } else {
      setShowFreeMixerModal(true)
    }
  }
  const handleJoinconversation = () =>{
    const signLink = process.env.THURSDAY_SIGNIN_URL
    const conversationlink = `${signLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.CREATE_CONVERSATIONS_FROM_UNIQUEMIXER_PAGE)
      setIsModalVisible(true)
    } else {
      window.location.href = conversationlink
    }
  }
  

  const handleSignIn = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL

    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      // window?.open(thursdayLink, "_self")
      window.location.href = thursdayLink
    }
  }

  // Cleanup needed change object to array
  const catchPhraseSectionInfo: any = {
    0: {
      bg: "var(--theme-orange)",
      phrase: (
        <>
          on thu<span className="salt-txt">r</span>sday, we don’t do b
          <span className="salt-txt">ooo</span>ring
        </>
      ),
      "btn-Txt": <>Be unboring</>,
      "btn-class": "btn-orangeTxt",
    },
    1: {
      bg: "var(--theme-green)",
      phrase: (
        <>
          grass is greener on thu<span className="salt-txt">r</span>sday
        </>
      ),
      "btn-Txt": <>Fertilize your grass</>,
      "btn-class": "btn-greenTxt",
    },
    2: {
      bg: "var(--theme-purple)",
      phrase: (
        <>
          For zoomers who hate to z<span className="salt-txt">ooo</span>m
        </>
      ),
      "btn-Txt": <>Stop zoooming</>,
      "btn-class": "btn-purpleTxt",
    },
    3: {
      bg: "var(--theme-yellow)",
      phrase: (
        <>
          we have a new <span className="salt-txt">favourite</span> day
        </>
      ),
      "btn-Txt": <>What’s yours?</>,
      "btn-class": "btn-yellowTxt",
    },

    4: {
      bg: "var(--theme-red)",
      phrase: (
        <>
          thu<span className="salt-txt">r</span>sday &#62; friday, no questions
        </>
      ),
      "btn-Txt": <>Change our mind</>,
      "btn-class": "btn-redTxt",
    },

    5: {
      bg: "var(--theme-lightBlue)",
      phrase: <>R.I.P friday, you served us well </>,
      "btn-Txt": <>Send your condolences</>,
      "btn-class": "btn-lightBlueTxt",
    },
  }

  useEffect(() => {
    setIsJSLoaded(false)
    utmCookieRequest()
  }, [])

  useEffect(() => {
    const vidEle: any = document.getElementsByClassName("hero-video")[0]

    /* Opacity handler for the video options */
    const opacityHandler = ({
      highestOpacityTxtIndex,
      allElements,
    }: OpacityHandlerInterface) => {
      const eleLength = allElements.length
      let count = 1

      // Set the center element opacity 100%
      allElements[highestOpacityTxtIndex].style.opacity = 1

      // Decrease the opacity of the other elements spreading outward from the center index
      while (
        highestOpacityTxtIndex - count >= 0 ||
        highestOpacityTxtIndex + count < eleLength
      ) {
        const lowerIndex = highestOpacityTxtIndex - count
        const higherIndex = highestOpacityTxtIndex + count

        if (allElements?.[lowerIndex]) {
          allElements[lowerIndex].style.opacity = `${0.9 - 0.15 * count}`
        }

        if (allElements?.[higherIndex]) {
          allElements[higherIndex].style.opacity = `${0.9 - 0.15 * count}`
        }

        count++
      }

      return
    }

    /* Scroll handler for the light blue section */
    const scrollHandler = () => {
      const advantagesSection = document.querySelector(
        ".advantages-section div"
      )
      const windowInnerHeight = window?.innerHeight
      const advantageTextHeight =
        advantagesSection?.childNodes[0]?.getBoundingClientRect()?.height

      const windowCenterRangerLower =
        (windowInnerHeight - advantageTextHeight) / 2
      const windowCenterRangerHigher =
        (windowInnerHeight + advantageTextHeight) / 2

      advantagesSection?.childNodes?.forEach((ele, index) => {
        let eleCoordinates = ele?.getBoundingClientRect()

        if (
          windowCenterRangerLower <= eleCoordinates.top &&
          windowCenterRangerHigher >= eleCoordinates.top
        ) {
          opacityHandler({
            highestOpacityTxtIndex: index,
            allElements: Array.from(advantagesSection.childNodes),
          })
        }
      })
    }

    /* Reset video container height when the video is resized */
    const resetVideoContainerHeight = () => {
      document.getElementsByClassName("floating-video")[0].style.minHeight = `${
        vidEle?.getBoundingClientRect()?.height + 5
      }px`
    }

    /* When the video is loaded reset the video container height.
     To avoid shift of the other content when the next videos are loaded  */
    const handleVideoLoaded = () => {
      if (vidEle?.readyState >= 3) {
        vidEle?.play()
        resetVideoContainerHeight()
      }
    }

    vidEle.addEventListener("loadeddata", handleVideoLoaded)
    document.addEventListener("scroll", scrollHandler)
    window?.addEventListener("resize", resetVideoContainerHeight)

    return () => {
      document.removeEventListener("scroll", scrollHandler)
      vidEle.removeEventListener("loadeddata", handleVideoLoaded)
      window?.removeEventListener("scroll", resetVideoContainerHeight)
    }
  }, [])

  useEffect(() => {
    const randomNumber = generateRandomNumber({ max: 5, min: 0 })
    const isParamRef = new URLSearchParams(window?.location?.search || "").get(
      "ref"
    )
    const extraParams: { "ref-url"?: string } = {}
    const currentPstTime = getPSTTime([])

    const day = currentPstTime?.getDate()
    const month = currentPstTime?.getMonth()

    const isRedirectedFromPH = isParamRef === "producthunt"

    const isLaunchWeek = month === 2 && day > 14 && day < 21

    const checkIfWithinLaunchWeek = isLaunchWeek

    if (document && document.referrer) {
      extraParams["ref-url"] = document.referrer
    }

    // Load twitter shoutout
    loadExternalScripts("https://embed.shoutout.so/embed.js")
    setCatchPhraseIndex(randomNumber)

    initAmplitude()
    sendAmplitudeData(AmplitudeEvents.MARKETING_PAGE_VISITED, extraParams)

    if (isRedirectedFromPH || checkIfWithinLaunchWeek) {
      setShowBanner(true)
    }

    if (isParamRef === "producthunt") {
      sendAmplitudeData(AmplitudeEvents.REDIRECT_FROM_PRODUCTHUNT)
    }
  }, [])

  const scrollVideoOptions = optionIndex => {
    const scrollSection = document.getElementsByClassName(
      "floating-video-options"
    )[0]
    // Calculate difference between left of the options container & the current selected option
    // Scroll the options left by the new calculated value
    const leftScrollLen =
      scrollSection?.children?.[optionIndex]?.getBoundingClientRect()?.left -
      scrollSection?.getBoundingClientRect()?.left

    scrollSection.scrollLeft = leftScrollLen
  }

  /**
   * Play next video by giving the index of the new video
   * @param videoIndex New Index of the video to be played
   */
  const playNextVideo = videoIndex => {
    let vidRef = heroVideos[videoIndex].ref
    if (vidRef.current) {
      vidRef.current.currentTime = 0
      vidRef.current?.play()

      setSelectedVideoOptionIndex(videoIndex)
    }

    // If window size is less we show a scrollbar for the video options
    // The selected option should scroll to the left automatically
    if (window.innerWidth <= 550) {
      scrollVideoOptions(videoIndex)
    }
  }

  /**
   * When the video is ended play the next video
   * @param ev Video ended event
   */
  const handleVideoOver = () => {
    let nextVideoIndex =
      selectedVideoOptionIndex === heroVideos.length - 1
        ? 0
        : selectedVideoOptionIndex + 1

    playNextVideo(nextVideoIndex)
  }

  const closeModal = () => {
    // The body scroll should be disable when the modal is open
    const body = document.querySelector("body")
    body.style.overflow = "auto"

    setIsModalVisible(false)
  }

  const handleEventBubbling = ev => {
    ev.stopPropagation()
  }

  // Move to utils
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: TITLE_METADATA,
          text: DESCRIPTION_METADATA,
          url: window?.location?.href,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing the link", error))
    } else {
      console.log("Share not supported on this browser, do it the old way.")
    }
  }

  const handleVideoReset = ev => {
    ev.target.pause()
    ev.target.currentTime = 0
  }

  const catchPhraseBtnClick = () => {
    const nextSectionIndex =
      catchPhraseIndex === Object.values(catchPhraseSectionInfo).length - 1
        ? 0
        : catchPhraseIndex + 1

    setCatchPhraseIndex(nextSectionIndex)
  }

  const handleThursdayRedirect = btnType => () => {
    sendAmplitudeData(btnType)
    window.location.href = "/"
  }

  return (
    <>
      <Seo
        title={CONVERSATIONS_TITLE_METADATA}
        description={CONVERSATIONS_DESCRIPTION_METADATA}
        canonical="https://thursday.social/two-truths-and-a-lie/"
      />

      {isModalVisible && (
        <div className="modal-bg" onClick={closeModal}>
          <div className="modal" onClick={handleEventBubbling}>
            <div className="cross" onClick={closeModal}>
              <CrossIcon />
            </div>
            <div className="argg-icon">
              <ArggIcon />
            </div>

            <p className="user-msg">
              Thursday is available only on desktop, for now
            </p>

            <p className="create-social-msg">Create a social from desktop</p>

            <button
              className="share-btn"
              onClick={handleShare}
              onMouseDown={handleMouseDownEvent}
              onMouseUp={handleMouseUpEvent}
            >
              <ShareIcon />
              &nbsp; Share
            </button>
          </div>
        </div>
      )}

      <div className="ttal-hero-section conversations-bg">
        <LogoHeader
          headerData={mixersWithConversations}
          logo="conversationsLogo"
          isLink={true}
        />

        <div className="ttal-heading">
          <div className="h1Text conversations-hero-text">
            Mourning the loss of watercooler chats?
            <div className="hero-mugshots">
              <div
                className="hero-mugshot"
                style={{ background: "var(--theme-red)" }}
              >
                <Popover content="Silent Samurai" styleClass="red" />
                <img src={HarshitMugshot} alt="Mugshot with Harshit's image" />
              </div>
              <div
                className="hero-mugshot"
                style={{ background: "var(--theme-yellow)" }}
              >
                <Popover content="Mr. talkative" styleClass="yellow" />
                <img src={NehalMugshot} alt="Mugshot with Nehal's image" />
              </div>
              <div
                className="hero-mugshot"
                style={{ background: "var(--theme-lightBlue)" }}
              >
                <Popover
                  content="Conversation starter"
                  styleClass="lightBlue"
                />
                <img src={RuchaMugshot} alt="Mugshot with Rucha's image" />
              </div>
              <div
                className="hero-mugshot"
                style={{ background: "var(--theme-green)" }}
              >
                <Popover content="Watercooler fanatic" styleClass="green" />
                <img src={NishithMugshot} alt="Mugshot with Nishith's image" />
              </div>
            </div>
            We have something better!
          </div>
        </div>

        <div className="thursday-logo-subheading">
          <div className="ttal-hero-subText h3Text">
            <span style={{ fontWeight: "bold" }}>3 new✨</span> conversational
            mixers by
          </div>

          <div
            className="logo-wrapper"
            onClick={handleThursdayRedirect(
              AmplitudeEvents.VISIT_MARKETING_PAGE_FROM_THURSDAY_LOGO_ON_CHARADES
            )}
          >
            <ThursdayLogo color="var(--theme-green)" />
          </div>
        </div>

        <div className="desktop-only-msg">
          <MonitorIcon />
          <p>Only on desktop, for now</p>
        </div>

        <div className="hero-btn-container">
          {isJSLoaded ? (
            <a
              onClick={handleSignIn(
                AmplitudeEvents.CREATE_SOCIAL_FROM_HERO_BUTTON
              )}
              className="theme-btn  btn-greenTxt"
              href={process.env.THURSDAY_SIGNIN_URL}
            >
              Get Started
            </a>
          ) : (
            <button
              onClick={handleSignIn(
                AmplitudeEvents.CREATE_SOCIAL_FROM_HERO_BUTTON
              )}
              className="theme-btn  btn-greenTxt"
              onMouseDown={handleMouseDownEvent}
              onMouseUp={handleMouseUpEvent}
            >
              Get Started
            </button>
          )}

          {/* <HeroScribble /> */}
        </div>
      </div>

      <div className="floating-video-container conversations-floating-video-container">
        <div className="floating-video-main">
          <div className="options-container">
            <div className="floating-video-options">
              {videoOptions.map((val, ind) => {
                return (
                  <div
                    className={`floating-video-option ${
                      selectedVideoOptionIndex === ind ? "active" : ""
                    }`}
                    key={ind}
                    onClick={() => playNextVideo(ind)}
                  >
                    {val[0]}
                    {val[1]}{" "}
                  </div>
                )
              })}
            </div>
          </div>

          <div className="floating-video">
            {heroVideos.map((val, ind) => (
              <video
                key={ind + "heroVideo"}
                ref={val.ref}
                src={val.video}
                autoPlay={ind === selectedVideoOptionIndex}
                playsInline
                style={{
                  display: ind === selectedVideoOptionIndex ? "block" : "none",
                }}
                muted={true}
                className="hero-video"
                onEnded={
                  ind === selectedVideoOptionIndex
                    ? handleVideoOver
                    : handleVideoReset
                }
              />
            ))}
          </div>
        </div>
      </div>

      <RemoteTeams showGoldenKitty={false} showFeatured />

      <div className="features-section">
        <div className="feature-container feature-1">
          <div className="feature-content">
            <SpeedIcon />

            <h3 className="feature-title h2Text">Speed 1-on-1</h3>

            <p className="feature-description body-txt">
              Fast paced & engaging. This mixer is 3 rounds, 4 minutes each,
              like going on a speed dating session, but with your teammates!
            </p>

            <button
              onClick={() =>
                startMixerHandler(
                  "80947f8e-2e81-4514-9dfb-7b41462e062d",
                  AmplitudeEvents.START_SPEED1ON1_FROM_CONVERSATIONS_PAGE,
                  setIsModalVisible
                )
              }
              className="txtBtn-with-arrow-section txt-btn body-txt lightBlue-txtBtn"
            >
              <p>Quick start</p>
              <RightArrow color="var(--theme-lightBlue)" />
            </button>
            <div className="fun-prompt-scribble">
              <FunPromptsScribble />
            </div>
          </div>

          <div className="feature-banner">
            <img
              className="banner-1-rotate"
              alt="Banner image for the feature section 1"
              src={FeatBanner1}
            />
          </div>
        </div>

        <div className="feature-container feature-2">
          <div className="feature-banner">
            <img
              className="banner-2-rotate"
              alt="Banner image for the feature section 2"
              src={FeatBanner2}
            />
          </div>

          <div className="feature-content ">
            <MtIcon />

            <h3 className="feature-title h2Text">Mindful Talks</h3>

            <p className="feature-description body-txt">
              From appreciation to inspiration, choose a topic and get into
              small groups for a discussion guided by prompts - 1 round, 12
              mins.
            </p>
            <button
              onClick={() =>
                startMixerHandler(
                  "5a94a2e7-16cc-4943-9147-974acc4d1f96",
                  AmplitudeEvents.START_MINDFUL_TALKS_FROM_CONVERSATIONS_PAGE,
                  setIsModalVisible
                )
              }
              className="txtBtn-with-arrow-section txt-btn body-txt green-txtBtn"
            >
              <p>Choose a topic</p>

              <RightArrow color="var(--theme-green)" />
            </button>
          </div>
        </div>

        <div className="feature-container feature-3 conversations-feature-3">
          <div className="feature-content">
            <div className="new-things-scribble">
              <FindNewThings />
            </div>

            <KytIconColor />

            <h3 className="feature-title h2Text">Know Your Team</h3>

            <p className="feature-description body-txt">
              Relish some quality conversations and get to know your teammates
              better in this 1-on-1 mixer. 1 round, 10 mins, talk your hearts
              out!
            </p>

            <button
              onClick={() =>
                startMixerHandler(
                  "7b2117be-84ca-4757-bbb5-473be75c6f42",
                  AmplitudeEvents.START_KYT_FROM_CONVERSATIONS_PAGE,
                  setIsModalVisible
                )
              }
              className="txtBtn-with-arrow-section txt-btn body-txt orange-txtBtn"
            >
              <p>Invite your team</p>

              <RightArrow color="var(--theme-orange)" />
            </button>
          </div>

          <div className="feature-banner">
            <img
              className="banner-3-rotate"
              alt="Banner image for the feature section 3"
              src={FeatBanner3}
            />
          </div>
        </div>
        <div className="ttal-features-section">
          <div className="ttal-feature-container">
            <div className="ttal-feature-content">
              <div className="feature-logo-wrapper">
                {" "}
                <PersonsSVG />
              </div>

              <h3 className="feature-title h2Text">Team-building</h3>

              <p className="ttal-feature-description body-txt">
                Our mixers are designed to be played in teams. Optimised for max
                conversations & total fun!
              </p>

              <button
                onClick={handleJoinconversation}
                className="txtBtn-with-arrow-section txt-btn body-txt purple-txtBtn"
              >
                <p>Build your team culture</p>
                <RightArrow color="var(--theme-purple)" />
              </button>
            </div>
          </div>

          <div className="ttal-feature-container">
            <div className="ttal-feature-content ">
              <div className="feature-logo-wrapper">
                <VideoSVG />
              </div>

              <h3 className="feature-title h2Text">Audio + Video</h3>

              <p className="ttal-feature-description body-txt">
                We have audio and video built right inside Thursday. Drop your
                boring socials on Zooom.
              </p>

              <button
                onClick={handleJoinconversation}
                className="txtBtn-with-arrow-section txt-btn body-txt red-txtBtn"
              >
                <p>Try it out</p>

                <RightArrow color="var(--theme-red)" />
              </button>
            </div>
          </div>

          <div className="ttal-feature-container">
            <div className="ttal-feature-content">
              <div className="feature-logo-wrapper">
                {" "}
                <StarSVG />
              </div>

              <h3 className="feature-title h2Text">4.2 Avg rating</h3>

              <p className="ttal-feature-description body-txt">
                All our mixers are 4+ rating from users, and constantly rising.
                We bet your team will love it.
              </p>

              <button
                onClick={handleJoinconversation}
                className="txtBtn-with-arrow-section txt-btn body-txt yellow-txtBtn"
              >
                <p>See all mixers</p>

                <RightArrow color="var(--theme-yellow)" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <TemplatesSection
        handleSignIn={handleSignIn}
        handleCreateSocial={handleCreateSocial}
        setIsModalVisible={setIsModalVisible}
      />

      <FreeMixerModal
        show={showFreeMixerModal}
        setShow={setShowFreeMixerModal}
        handleSignIn={handleSignIn}
        handleCreateSocial={handleCreateSocial}
      />

      <Testimonials />

      {/* <PaymentSection
        handleStart14DaysTrial={handleStart14DaysTrial}
        handleCreateSocial={handleCreateSocial}
      /> */}

      <FaqSection />

      <div className="advantages-section">
        <div className="h1Text">
          <div>No installing.</div>

          <div>No signup.</div>

          <div>No ads.</div>

          <div>No work.</div>

          <div>No boring.</div>
        </div>
      </div>

      <div className="shoutOut-section">
        <div className="h2Text">Launch day love!</div>

        <div className="shoutout-embed" data-wall="ThursdayByFolly"></div>
      </div>

      <div
        className="catchPhrase-section"
        style={{
          background: `${catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.bg}`,
        }}
      >
        <img
          className="background-blue-banner"
          src={CatchPhraseBlue}
          alt="background-blue-banner"
        />

        <img
          className="background-yellow-banner"
          src={CatchPhraseYellow}
          alt="background-yellow-banner"
        />

        <img
          className="background-red-banner"
          src={CatchPhraseRed}
          alt="background-red-banner"
        />

        <img
          className="background-purple-banner"
          src={CatchPhrasePurple}
          alt="background-purple-banner"
        />

        <img
          className="background-orange-banner"
          src={CatchPhraseOrange}
          alt="background-orange-banner"
        />

        <img
          className="background-green-banner"
          src={CatchPhraseGreen}
          alt="background-green-banner"
        />

        <div className="catchPhrase h1Text">
          {catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.phrase}
        </div>

        <button
          onClick={catchPhraseBtnClick}
          onMouseDown={handleMouseDownEvent}
          onMouseUp={handleMouseUpEvent}
          className={
            "theme-btn " +
            catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.["btn-class"]
          }
        >
          {catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.["btn-Txt"]}
        </button>
      </div>

      {showBanner && (
        <FloatingBannerContainer>
          <MarketingFloatingBanner></MarketingFloatingBanner>
        </FloatingBannerContainer>
      )}
      <ColorSelector clickHandler={setCatchPhraseIndex} />

      <Footer />
    </>
  )
}

export default ConversationPage
