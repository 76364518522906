import React from "react"

const MtIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.66669"
        y="18.3333"
        width="26.6667"
        height="3.33333"
        rx="1.66667"
        fill="#475766"
      />
      <circle
        cx="8.33329"
        cy="28.3333"
        r="7.49667"
        fill="#1EB482"
        stroke="#FFF9E6"
        strokeWidth="1.66"
      />
      <circle
        cx="31.6667"
        cy="28.3333"
        r="7.49667"
        fill="#1EB482"
        stroke="#FFF9E6"
        strokeWidth="1.66"
      />
      <circle
        cx="20"
        cy="28.3333"
        r="9.16333"
        fill="#1EB482"
        stroke="#FFF9E6"
        strokeWidth="1.66"
      />
      <rect
        x="6.66669"
        y="5"
        width="26.6667"
        height="3.33333"
        rx="1.66667"
        fill="#475766"
      />
      <rect
        x="6.66669"
        y="11.6667"
        width="26.6667"
        height="3.33333"
        rx="1.66667"
        fill="#475766"
      />
    </svg>
  )
}

export default MtIcon
